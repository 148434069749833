.common-problem {
    width: 100%;
    height: 100%;

    .common-problem-title {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #26C6A6;
    }

    .common-problem-content {
        width: 100%;
        margin-top: 40px;

        .common-problem-content-top {
            width: 100%;
            height: 33px;
            display: flex;
            align-items: center;

            .left-beginning {
                width: 29px;
                height: 26px;
                background: #26C6A6;
                border-radius: 4px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .right-title {
                margin-left: 18px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 32px;
            }
        }

        .common-problem-content-bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 12px;

            .left-beginning {
                width: 29px;
                height: 26px;
                background: #CECFD6;
                border-radius: 4px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .right-title {
                flex: 1;
                margin-left: 18px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                line-height: 32px;
            }
        }
    }
}