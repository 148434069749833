* {
    margin: 0;
    padding: 0;
}

.phone-maintenance {
    min-width: 1230px;
    width: 100%;
    overflow: hidden;

    .phone-maintenance-video {
        width: 100%;
        height: 540px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .phone-maintenance-type {
        width: 100%;
        height: 293px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);

        .phone-maintenance-type-box {
            width: 1200px;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .phone-maintenance-type-title {
                position: absolute;
                top: 59px;
                left: 0;
                width: 100%;
                height: 45px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .ant-tabs {
                    height: 45px;
                }

                .ant-tabs .ant-tabs-tab {
                    // width: 164px;
                    height: 100%;
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    color: #2C2C2D;
                }

                .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: #000;
                }
                .ant-tabs .ant-tabs-ink-bar{
                    background: rgba(38, 198, 166, 1);
                }
                .ant-tabs-top >.ant-tabs-nav::before{
                    border: none !important;
                }
            }

            .phone-maintenance-iconImg {
                position: absolute;
                bottom: 0;
                height: 167px;
                width: 1920px;
                border-top: 1px solid #E6E6E6;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .phone-maintenance-iconImg-center {
                    width: 1200px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .phone-maintenance-iconImg-element {
                        width: 151px;
                        height: 167px;
                        border: 1px solid #EFEFEF;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;
                        cursor: pointer;
                        transition: all 0.2s;
                        text-align: center;
                        img {
                            width: 110px;
                            height: 110px;
                        }

                    }

                    .phone-maintenance-iconImg-element:hover {
                        box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
                        transform: scale(1.01)
                    }

                    .phone-maintenance-iconImg-active {
                        border: 2px solid #26C6A6;
                        color: #26C6A6;
                        background: #FFFFFF;
                        box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.2), 0px 8px 20px 0px rgba(106, 149, 145, 0.1);
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .phone-maintenance-content {
        width: 100%;
        // height: 1660px;
        background: #F9F9FA;
        display: flex;
        justify-content: center;
        align-items: center;

        .phone-maintenance-content-box {
            width: 1230px;
            height: 100%;

            .phone-maintenance-content-type {
                width: 228px;
                height: 276px;
                background: #FFFFFF;
                border-radius: 4px;
                margin-left: 15px;
                margin-top: 40px;
                // margin-bottom: 80px;
                float: left;
                position: relative;
                cursor: pointer;

                img {
                    width: 138px;
                    height: 138px;
                    position: absolute;
                    top: 40px;
                    left: 45px;
                }

                .phone-maintenance-content-type-name {
                    position: absolute;
                    width: 100%;
                    height: 26px;
                    top: 210px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                transition: all 0.3s;
            }

            .phone-maintenance-content-type:hover {
                background: #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.2), 0px 8px 20px 0px rgba(106, 149, 145, 0.1);
                border-radius: 4px;
            }
        }
    }

    .phone-maintenance-content-bottom {
        width: 100%;
        height: 80px;
        background: #F9F9FA;
    }
}