.contact-us {
    width: 100%;
    height: 100%;

    .contact-us-title {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #26C6A6;
    }

    .contact-us-map {
        width: 100%;
        height: 396px;
        position: relative;
        margin-top: 40px;

        .map-img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .contact-us-items {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 445px;
            height: 93px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            border-bottom: 1px solid #E6E6E6;

            div {
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 71px;
                height: 3px;
                background-color: #26C6A6;
            }
        }

        .contact-us-element {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 445px;
            // background-color: red;
            height: 93px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            border-bottom: 1px solid #E6E6E6;

            .element-img {
                width: 48px;
                height: 48px;
                z-index: 99;
            }

            .icon {
                width: 48px;
                height: 48px;
                z-index: 99;
            }

            .element-content {
                height: 48px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 16px;

                .top-title {
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                }

                .bottom-content {
                    height: 26px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                    line-height: 26px;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }

        .contact-us-element-bottom {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 650px;
            // background-color: red;
            height: 93px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            // border-bottom: 1px solid #E6E6E6;

            .element-img {
                width: 48px;
                height: 48px;
                z-index: 99;
            }

            .icon {
                width: 48px;
                height: 48px;
                z-index: 99;
            }

            .element-content {
                height: 48px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 16px;

                .top-title {
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                }

                .bottom-content {
                    height: 26px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                    line-height: 26px;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }
}