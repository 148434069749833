.msx-information {
    width: 100%;
    // height: 673px;
    background: #F9F9FA;
    display: flex;
    align-items: center;
    justify-content: center;

    .information {
        width: 1200px;
        height: 100%;

        .information-title {
            width: 100%;
            height: 118px;
            display: flex;
            justify-content: center;

            .information-title-zone {
                width: 1200px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                .information-title-zone-top {
                    width: 282px;
                    height: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .information-title-zone-top-icon {
                        width: 28px;
                        height: 22px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .information-title-zone-top-title {
                        width: 190px;
                        height: 45px;
                        background: #F9F9FA;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2C2C2D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .information-title-zone-bottom {
                    width: 308px;
                    height: 40px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #6F7174;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .information-type {
            position: relative;
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .information-type-content {
                width: 110px;
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                line-height: 25px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;

                .icon {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 2px;
                }
            }

            .active {
                color: #26C6A6;
            }
        }

        .information-type-display {
            width: 100%;
            // height: 460px;
            // background-color: #6F7174;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content:space-evenly;
            margin-bottom: 60px;

            .information-type-display-block {
                width: 384px;
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                transition: all 0.2s;
                cursor: pointer;

                .information-type-display-img {
                    width: 100%;
                    height: 230px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .information-type-display-all {
                    position: relative;
                    width: 100%;
                    height: 230px;

                    .information-type-display-all-time {
                        position: absolute;
                        width: 100%;
                        height: 25px;
                        top: 17px;
                        left: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #26C6A6;

                        .icon {
                            width: 18px;
                            height: 18px;
                            margin-bottom: 2px;
                        }
                    }

                    .information-type-display-all-title {
                        position: absolute;
                        padding-left: 20px;
                        padding-right: 20px;
                        top: 54px;
                        width: 100%;
                        height: 30px;
                        font-size: 22px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                        line-height: 30px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .information-type-display-all-content {
                        position: absolute;
                        width: 100%;
                        height: 64px;
                        top: 94px;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6F7174;
                        line-height: 32px;
                        overflow: hidden; //超出的文本隐藏
                        text-overflow: ellipsis; //溢出用省略号显示
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // 超出多少行
                        -webkit-box-orient: vertical;
                    }

                    .information-type-display-all-detail {
                        width: 120px;
                        height: 32px;
                        position: absolute;
                        top: 178px;
                        left: 20px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #26C6A6;
                        line-height: 32px;
                        // background-color: red;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .horizontal-line {
                            width: 0px;
                            height: 2px;
                            background: #26C6A6;
                            border-radius: 1px;
                            transition: all 0.3s;
                            margin-left: 11px;
                        }

                        .anticon-right {
                            position: relative;
                            right: 10.52px;
                        }
                    }

                    .information-type-display-all-detail:hover .horizontal-line {
                        width: 16px;
                    }
                }
            }

            .information-type-display-block:hover {
                box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
                transform: scale(1.01)
            }
        }
    }
}