* {
    margin: 0;
    padding: 0;
}

.placeorder {
    min-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .placeorder-top {
        width: 100%;
        height: 187px;
        margin-top: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F9F9FA;
        position: relative;

        .backsBottom{
            width: 100%;
            height: 162px;
            position: absolute;
            left: 0;
            top: 187px;
            background: #F9F9FA;
            z-index: -1;
        }
        .placeorder-top-box {
            width: 1200px;
            height: 100%;
            background: #F9F9FA;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .placeorder-content {
                width: 1200px;
                height: 100%;
                background: #F9F9FA;
                position: relative;
                display: flex;
                justify-content: center;

                .placeorder-content-title {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    width: 165px;
                    height: 26px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;

                    span:nth-child(1) {
                        color: #6F7174;
                    }
                }

                .placeorder-content-steps {
                    width: 998px;
                    height: 40px;
                    margin-top: 109px;

                    .ant-steps-item-finish .ant-steps-item-icon {
                        background: none;
                        border-color: rgba(38, 198, 166, 1);
                        color: rgba(38, 198, 166, 1);
                    }

                    .anticon,
                    .anticon-check,
                    .ant-steps-finish-icon {
                        color: rgba(38, 198, 166, 1);
                    }

                    .ant-steps-item-process .ant-steps-item-icon {
                        background-color: rgba(38, 198, 166, 1);
                        border-color: rgba(38, 198, 166, 1);
                    }

                    .ant-steps-item-wait .ant-steps-item-icon {
                        background: none;
                        border-color: #E6E6E6;
                    }

                    .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
                        background-color: rgba(38, 198, 166, 1);
                    }
                }
            }
            .content-active{
                background: #FFFFFF;
            }
        }

    }
    .top-active{
        background: #FFFFFF;
    }
}