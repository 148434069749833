.aboutus-me {
    width: 100%;
    height: 100%;

    .aboutus-me-title {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #26C6A6;
    }

    .company-introduction {
        width: 100%;
        height: 191px;

        .company-introduction-name {
            width: 100%;
            height: 33px;
            padding-left: 8px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            line-height: 33px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .line {
                width: 4px;
                height: 23px;
                background: #26C6A6;
            }

            .name {
                margin-left: 8px;
            }
        }

        .company-introduction-content {
            margin-top: 20px;
            width: 100%;
            height: 128px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2C2C2D;
            line-height: 32px;

        }
        .company-introduction-content-tip {
            font-size: 12px;
            color: #999;

        }
    }

    .service-content {
        margin-top: 40px;
        width: 100%;
        height: 117px;

        .service-content-name {
            width: 100%;
            height: 33px;
            padding-left: 8px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            line-height: 33px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .line {
                width: 4px;
                height: 23px;
                background: #26C6A6;
            }

            .name {
                margin-left: 8px;
            }
        }

        .service-content-content {
            margin-top: 20px;
            width: 100%;
            height: 64px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2C2C2D;
            line-height: 32px;

        }
    }




    .platform-advantage {
        margin-top: 40px;
        width: 100%;

        .platform-advantage-name {
            width: 100%;
            height: 33px;
            padding-left: 8px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            line-height: 33px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .line {
                width: 4px;
                height: 23px;
                background: #26C6A6;
            }

            .name {
                margin-left: 8px;
            }
        }

        .platform-advantage-content {
            margin-top: 20px;
            width: 100%;
            height: 68px;

            .title {
                width: 100%;
                height: 32px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                display: flex;
                align-items: center;
            }

            .context {
                width: 100%;
                height: 32px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
                display: flex;
                align-items: center;
            }
        }
    }
}