.coupon-home {
    width: 100%;
    height: 184px;
    display: flex;
    align-items: center;
    justify-content: center;

    .coupon-home-box {
        width: 1200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        overflow: hidden;

        .coupon-home-receive {
            width: 384px;
            height: 102px;
            background: #FD5F28 linear-gradient(180deg, #FFB439 0%, rgba(255, 177, 74, 0) 100%);
            box-shadow: 0px 2px 10px 0px rgba(232, 0, 0, 0.1);
            border-radius: 12px;
            border: 1px solid #FFFFFF;
            display: flex;
            flex-direction: row;
            align-items: center;

            // justify-content: center;
            .coupon-home-receive-back-icon {
                position: relative;
                width: 262px;
                height: 86px;
                display: flex;
                align-items: center;
                justify-content: center;

                .coupon-home-receive-icon-o {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 13px;
                }

                .coupon-home-receive-amount {
                    height: 74px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    z-index: 999;
                    font-size: 64px;
                    font-family: DINAlternate-Bold, DINAlternate;
                    font-weight: bold;
                    color: #FE4300;
                    margin-right: 20px;
                    span {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FE4300;
                        padding-bottom: 8px;
                    }
                }

                .coupon-home-receive-amount-title {
                    height: 74px;
                    display: flex;
                    flex-direction: column;
                    z-index: 999;

                    .coupon-home-receive-amount-title-top {
                        flex: 1;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FE4300;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        padding-left: 8px;
                    }

                    .coupon-home-receive-amount-title-bottom {
                        flex: 1;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FE4300;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-left: 8px;
                        padding-bottom: 3px;
                    }
                }

            }

            .coupon-home-receive-back-icon-t {
                position: relative;
                flex: 1;
                height: 100%;
                // background-color: red;
                display: flex;
                align-items: center;

                .coupon-home-receive-icon-t {
                    position: absolute;
                    width: 68px;
                    height: 68px;
                    left: 28px;
                    cursor: pointer;
                }
            }

        }
    }

}
.active{
    height: 20px;
}