.dcservice {
    position: relative;
    width: 100%;
    height: 534px;
    background-color: aqua;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .dcservice-form {
        position: relative;
        width: 1200px;
        height: 454px;
        background: #FFFFFF;
        border-radius: 4px;
        z-index: 1;

        .dcservice-form-order-prompt {
            position: absolute;
            top: 59px;
            left: 34px;
            width: 384px;
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            line-height: 33px;

            .underline {
                font-size: 18px;
            }
        }

        .dcservice-form-response {
            position: absolute;
            width: 384px;
            height: 25px;
            top: 108px;
            left: 35px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6F7174;

            .dcservice-form-response-imgicon {
                position: relative;
                width: 22px;
                height: 21px;

                .icon{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .dcservice-form-button {
            position: absolute;
            left: 34px;
            top: 157px;
            width: 200px;
            height: 56px;
            background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 28px;
            border: 2px solid #FFFFFF;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #26C6A6;

            .dcservice-form-button-icon {
                position: relative;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .dcservice-form-process {
            position: absolute;
            width: 626px;
            height: 157px;
            top: 257px;
            left: 34px;
            display: flex;
            justify-content: center;
            align-items: center;

            .dcservice-form-process-box {
                position: relative;
                width: 100%;
                height: 100%;

                .fwlc {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                .customer-service {
                    position: absolute;
                    width: 299px;
                    height: 275px;
                    left: 293px;
                    top: -275px;
                }
            }
        }

        .dcservice-form-orderform {
            width: 486px;
            height: 374px;
            border-radius: 4px;
            position: absolute;
            top: 40px;
            right: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .dcservice-form-orderform-box {
                position: relative;
                width: 100%;
                height: 100%;
                background: #F9F9FA;
                border-radius: 4px;

                .dcservice-form-orderform-name {
                    position: absolute;
                    width: 200px;
                    height: 33px;
                    top: 23px;
                    left: 32px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2C2C2D;

                    .dcservice-form-orderform-name-icon {
                        position: relative;
                        width: 28px;
                        height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .dcservice-form-orderform-inputName {
                    position: absolute;
                    top: 82px;
                    left: 32px;
                    width: 422px;
                    height: 52px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .ant-input {
                        width: 100% !important;
                        height: 100% !important;
                        border: none !important;
                        padding-left: 16px !important;
                        font-size: 18px !important;

                        &::placeholder {
                            font-size: 18px !important;
                            font-family: PingFangSC-Regular, PingFang SC !important;
                            font-weight: 400 !important;
                            color: #8B8F97 !important;
                        }
                    }
                }

                .dcservice-form-orderform-inputPhone {
                    position: absolute;
                    top: 150px;
                    left: 32px;
                    width: 422px;
                    height: 52px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .ant-input {
                        width: 100% !important;
                        height: 100% !important;
                        border: none !important;
                        padding-left: 16px !important;
                        font-size: 18px !important;

                        &::placeholder {
                            font-size: 18px !important;
                            font-family: PingFangSC-Regular, PingFang SC !important;
                            font-weight: 400 !important;
                            color: #8B8F97 !important;
                        }
                    }
                }

                .dcservice-form-orderform-inputCode {
                    position: absolute;
                    top: 218px;
                    left: 32px;
                    width: 422px;
                    height: 52px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dcservice-form-orderform-inputCode-box {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        .ant-input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100% !important;
                            height: 100% !important;
                            border: none !important;
                            padding-left: 16px !important;
                            font-size: 18px !important;

                            &::placeholder {
                                font-size: 18px !important;
                                font-family: PingFangSC-Regular, PingFang SC !important;
                                font-weight: 400 !important;
                                color: #8B8F97 !important;
                            }
                        }

                        .dcservice-form-orderform-inputCode-box-getCode {
                            position: absolute;
                            top: 15px;
                            right: 16px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            .dcservice-form-orderform-inputCode-box-getCodeBtn {
                                width: 100%;
                                height: 100%;
                                background: none;
                                border: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #000;
                            }

                            .active {
                                color: #26C6A6;
                            }
                        }
                    }

                }

                .dcservice-form-orderform-inputSubmit {
                    position: absolute;
                    top: 294px;
                    left: 32px;
                    width: 422px;
                    height: 56px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    overflow: hidden;

                    .dcservice-form-orderform-inputBtn {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #26C6A6;
                        border-radius: 4px;
                        font-size: 22px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        border: none;
                        z-index: 9999;
                    }
                }
            }
        }
    }
}

.ant-message {
    z-index: 99999999999999 !important;
}