* {
    margin: 0;
    padding: 0;
}

.msxconsultation {
    min-width: 1200px;
    width: 100%;
    overflow: hidden;

    .msxconsultation-header {
        width: 100%;
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .msxconsultation-header-box {
            position: relative;
            width: 1200px;
            height: 100%;

            .msxconsultation-header-title {
                margin-top: 200px;
                width: 276px;
                height: 45px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 45px;

            }

            .msxconsultation-header-tabs {
                margin-top: 16px;
                width: 175px;
                height: 26px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                line-height: 26px;

                .current {
                    color: #000;
                }

                cursor: pointer;
            }

            .msxconsultation-header-advisory-floating-layer {
                position: absolute;
                left: 0;
                bottom: -60px;
                width: 100%;
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FFFFFF;

                .msxconsultation-header-advisory-content {
                    height: 73px;
                    width: 1140px;
                    border-bottom: 1px solid #E6E6E6;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .title-left {
                        width: 200px;
                        height: 33px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                    }

                    .title-right {
                        width: 194px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .message {
                            height: 100%;
                            width: 72px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2C2C2D;
                            cursor: pointer;
                        }
                        .active{
                            color: #26C6A6;
                        }
                    }
                }
            }
        }
    }

    .msxconsultation-content {
        width: 100%;
        max-height: 2376px;
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;

        .msxconsultation-content-box {
            width: 1200px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .msxconsultation-content-items-box {
                width: 1140px;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;

                .msxconsultation-content-items {
                    width: 1140px;
                    height: 264px;
                    border-bottom: 1px solid #E6E6E6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.4s;

                    .item-box {
                        width: 100%;
                        height: 204px;

                        .item-box-img {
                            height: 100%;
                            width: 340px;
                            float: left;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .item-box-text {
                            width: 605px;
                            height: 100%;
                            float: left;

                            .item-box-text-top {
                                padding-left: 32px;
                                margin-top: 16px;
                                width: 100%;
                                height: 30px;
                                font-size: 22px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #2C2C2D;
                                line-height: 30px;
                            }

                            .item-box-text-middle {
                                padding-left: 32px;
                                margin-top: 24px;
                                width: 100%;
                                height: 64px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                /* 溢出部分隐藏 */
                                overflow: hidden;
                                /* 溢出部分用省略号代替 */
                                text-overflow: ellipsis;
                                /* 弹性伸缩盒子模型显示 */
                                display: -webkit-box;
                                /* 限制一个块元素显示的文本行数 */
                                -webkit-line-clamp: 2;
                                /* 设置伸缩盒对象子元素的排列方式 */
                                -webkit-box-orient: vertical;
                                line-height: 30px;
                                cursor: pointer;
                            }

                            .item-box-text-bottom {
                                margin-top: 38px;
                                padding-left: 32px;
                                width: 172px;
                                height: 32px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #26C6A6;
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .horizontal-bottom-line {
                                    width: 0px;
                                    height: 2px;
                                    border-radius: 1px;
                                    transition: all 0.3s;
                                    margin-left: 11px;
                                    float: left;
                                    background-color: #26C6A6;
                                }

                                .anticon-right {
                                    position: relative;
                                    right: 10.52px;
                                }
                            }
                        }

                        .item-box-right {
                            width: 2px;
                            height: 100%;
                            float: left;
                            margin-left: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            div {
                                width: 2px;
                                height: 44px;
                                background: #E6E6E6;
                            }
                        }

                        .item-box-right-time {
                            height: 100%;
                            float: left;
                            margin-left: 32px;

                            .item-box-right-time-month {
                                margin-top: 54px;
                                height: 45px;
                                font-size: 32px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #6F7174;
                            }

                            .item-box-right-time-year {
                                height: 26px;
                                font-size: 18px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #6F7174;
                            }
                        }
                    }
                }

                .msxconsultation-content-items:hover {
                    background: #F9F9FA;
                    box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
                    transform: scale(1.01);
                    cursor: pointer;
                }

                .msxconsultation-content-items:hover .item-box-text .item-box-text-bottom .horizontal-bottom-line {
                    width: 16px;
                }
            }
        }
    }

    .msxconsultation-page {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .msxconsultation-page {
            width: 1200px;
            height: 100%;
        }
    }
}

.ant-pagination-item a {
    border-radius: 4px;
    border: 1px solid #E6E6E6;
}

.ant-pagination-item-active a {
    background: #26C6A6;
    border-radius: 4px;
    color: #FFFFFF;
    border-color: #26C6A6 !important;
}

.ant-pagination .ant-pagination-item-active {
    border: none;
}

.ant-pagination .ant-pagination-item-active a {
    color: #FFFFFF;
}

.ant-pagination .ant-pagination-item-active a:hover {
    color: #FFFFFF;
}

.ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px !important;
    border: 1px solid #E6E6E6 !important;
}

.ant-pagination-jump-next-custom-icon,
.ant-pagination-item-link {
    border: none;
}

.ant-pagination-item-container {
    margin-left: -10px;
}