* {
    margin: 0;
    padding: 0;
}

.aboutus {
    min-width: 1200px;
    width: 100%;

    .aboutus-top {
        width: 100%;
        height: 670px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit: cover;
        }

        .aboutus-top-box {
            width: 1200px;
            height: 100%;
            position: relative;

            .introduce-title {
                position: absolute;
                top: 257px;
                left: 0;
                height: 93px;
                font-size: 68px;
                font-family: AlibabaPuHuiTiB;
                color: #2C2C2D;
                line-height: 93px;
                letter-spacing: 1px;
                font-weight: 800;
                cursor: pointer;
            }

            .introduce-line {
                position: absolute;
                top: 372px;
                left: 0;
                width: 88px;
                height: 6px;
                background: #26C6A6;
            }

            .introduce-detail {
                position: absolute;
                left: 0;
                top: 403px;
                width: 518px;
                height: 114px;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
                line-height: 38px;
            }
        }
    }

    .aboutus-title {
        width: 100%;
        height: 118px;
        display: flex;
        justify-content: center;

        .aboutus-title-zone {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .aboutus-title-zone-top {
                width: 282px;
                height: 45px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .aboutus-title-zone-top-icon {
                    width: 28px;
                    height: 22px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .aboutus-title-zone-top-title {
                    width: 190px;
                    height: 45px;
                    // background: #F9F9FA;
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2C2C2D;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .aboutus-title-zone-bottom {
                width: 308px;
                height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #6F7174;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .aboutus-advantage {
        width: 100%;
        height: 418px;
        display: flex;
        justify-content: center;
        align-items: center;

        .aboutus-advantage-box {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            .aboutus-advantage-items {
                width: 282px;
                height: 284px;
                // background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
                background: #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1);
                border-radius: 4px;
                margin-top: 74px;
                position: relative;
                display: flex;
                // justify-content: center;
                align-items: center;
                flex-direction: column;

                // 图片抖动
                .items-icon {
                    position: absolute;
                    left: 97px;
                    top: -40px;
                    width: 80px;
                    height: 80px;
                }

                .items-icon .icon-box {
                    width: 80px;
                    height: 80px;
                    float: left;
                    position: relative;
                    list-style: none;
                }

                .items-icon .icon-box img {
                    width: 80px;
                    height: 80px;
                    animation: jump 3s ease infinite;
                }

                @keyframes jump {
                    0% {
                        transform: translateY(0) scale(1, 1);
                    }

                    /* 中间状态图片位移并且拉伸 */
                    50% {
                        transform: translateY(-30px) scale(0.97, 1.03);
                    }

                    100% {
                        transform: translateY(0) scale(1, 1);
                    }
                }

                @keyframes shadow {
                    0% {
                        transform: scale(1);
                        opacity: 1;
                        filter: blur(10px);
                    }

                    /* 投影缩放+虚化 */
                    50% {
                        transform: scale(0.8);
                        opacity: 0.7;
                        filter: blur(20px);
                    }

                    100% {
                        transform: scale(1);
                        opacity: 1;
                        filter: blur(10px);
                    }
                }

                // 以上为图片抖动效果

                .items-title {
                    margin-top: 96px;
                    height: 33px;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                    line-height: 33px;
                }

                .items-el {
                    margin-top: 4px;
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;
                    line-height: 22px;
                }

                .items-line {
                    margin-top: 24px;
                    width: 36px;
                    height: 4px;
                    background: #26C6A6;
                    border-radius: 2px;
                }

                .items-advantage {
                    margin-top: 24px;
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;
                    line-height: 25px;
                }
            }
        }
    }
    .aboutus-footer{
        width: 100%;
        height: 100px;
    }
}