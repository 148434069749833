.success-order {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .success-order-box {
        width: 1200px;
        height: 100%;
        background-color: rgba(249, 249, 250, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .success-order-qrcode {
            width: 254px;
            height: 254px;
            margin-top: 50px;

            img {
                width: 100%;
                height: 100%;

            }
        }

        .success-order-dotted-line {
            width: 28px;
            height: 28px;
            margin-top: 15px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .success-order-title {
            width: 100%;
            height: 45px;
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #26C6A6;
            letter-spacing: 6px;
            -webkit-background-clip: text;
        }

        .success-order-detail {
            width: 100%;
            height: 26px;
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6F7174;
        }
    }
}