.sliderCaptcha {
  height: 54px;

  --rcsc-primary: #26C6A6;
  --rcsc-primary-light: #C2F0E7;

  .rc-slider-captcha-control {
    height: 54px !important;
  }

  .rc-slider-captcha-control-tips {
    height: 100%;
  }

  .rc-slider-captcha-button {
    width: 64px;
    background: #26C6A6;
  }
}