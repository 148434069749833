.footer-directory {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2F2F2F;
    overflow: hidden;


    .footer-directory-content {
        width: 1200px;
        height: 100%;
        position: relative;


        .footer-directory-ul-about {
            position: absolute;
            height: 190px;
            width: 155px;
            top: 60px;
            left: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            // align-items:safe;
            justify-content: space-between;

            li {
                width: 155px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
                transition: all 0.2s;
            }

            li:hover {
                font-weight: 800;
            }

            .li-img {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    position: absolute;
                    top: 0;
                    left: -30px;
                    height: 100%;
                    width: 102px;
                }
            }
        }

        .footer-directory-ul-help {
            position: absolute;
            height: 190px;
            width: 155px;
            top: 60px;
            left: 275px;
            list-style: none;
            display: flex;
            flex-direction: column;
            // align-items:safe;
            justify-content: space-between;

            li {
                width: 155px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
            }

            li:hover {
                font-weight: 800;
            }

            .footer-directory-ul-li-name {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
            }
        }

        .footer-directory-ul-Business-cooperation {
            position: absolute;
            height: 190px;
            width: 155px;
            top: 60px;
            left: 550px;
            list-style: none;
            display: flex;
            flex-direction: column;
            // align-items:safe;
            justify-content: space-between;

            li {
                width: 155px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
            }

            li:hover {
                font-weight: 800;
            }

            .footer-directory-ul-li-name {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
            }
        }

        .footer-directory-qrCode {
            height: 168px;
            width: 112px;
            position: absolute;
            top: 60px;
            left: 750px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .footer-directory-qrCode-img {
                width: 100%;
                height: 112px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .footer-directory-qrCode-title {
                width: 100%;
                height: 48px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

        .footer-directory-dividing-line {
            position: absolute;
            width: 1px;
            height: 172px;
            opacity: 0.2;
            border: 1px solid #FFFFFF;
            top: 65px;
            left: 964px;
        }

        .footer-directory-phone {
            position: absolute;
            top: 100px;
            left: 1040px;
            width: 258px;
            height: 85px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .footer-directory-phone-top {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 36px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #26C6A6;
            }

            .footer-directory-phone-bottom {
                width: 100%;
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}