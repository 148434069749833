.user-evaluation {
    width: 100%;
    height: 542px;
    display: flex;
    justify-content: center;
    align-items: center;

    .user-evaluation-center {
        height: 100%;
        width: 1200px;

        .user-evaluation-center-box {
            width: 100%;
            height: 118px;
            display: flex;
            justify-content: center;

            .user-evaluation-zone {
                width: 1200px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                .user-evaluation-zone-top {
                    width: 230px;
                    height: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .user-evaluation-zone-top-icon {
                        width: 28px;
                        height: 22px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .user-evaluation-zone-top-title {
                        width: 138px;
                        height: 45px;
                        background-color: #FFFFFF;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2C2C2D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .user-evaluation-zone-bottom {
                    width: 308px;
                    height: 40px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #6F7174;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .user-evaluation-banner {
            width: 100%;
            height: 424px;
            display: flex;
            justify-content: center;
            align-items: center;

            .slick-arrow {
                width: 46px;
                height: 58px;
                background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(211, 217, 216, 0.1);
                border-radius: 4px;
                margin-left: -30px;
            }

            .slick-arrow::before {
                color: rgba(38, 198, 166, 1);
            }

            .slick-next {
                width: 46px;
                height: 58px;
                background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(211, 217, 216, 0.1);
                border-radius: 4px;
                margin-right: -30px;
            }

            .slick-next::before {
                color: rgba(38, 198, 166, 1);
            }

            .user-evaluation-banner-box {
                width: 100%;
                height: 380px;
                // background-color: red;

                .evaluation-banner {
                    width: 384px !important;
                    height: 380px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 7px;

                    .evaluation-banner-Within {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99999999;

                        .evaluation-banner-Within-box {
                            width: 384px;
                            height: 354px;
                            background: #FFFFFF;
                            box-shadow: 0 4px 10px 0 rgba(48, 55, 66, 0.15);
                            border-radius: 4px;
                            position: relative;


                            .head-picture {
                                position: absolute;
                                top: 32px;
                                left: 24px;
                                width: 58px;
                                height: 58px;
                                border-radius: 50%;
                                overflow: hidden;

                                .icon {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .evaluation-banner-name {
                                position: absolute;
                                top: 36px;
                                left: 104px;
                                width: 150px;
                                height: 59px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                .evaluation-banner-name-n {
                                    width: 100%;
                                    height: 30px;
                                    font-size: 22px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #2C2C2D;
                                    line-height: 30px;
                                }

                                .evaluation-banner-name-x {
                                    width: 100%;
                                    height: 25px;
                                }
                            }

                            .evaluation-banner-time {
                                position: absolute;
                                top: 40px;
                                right: 24px;
                                width: 100px;
                                height: 22px;
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                line-height: 22px;
                            }

                            .evaluation-banner-content {
                                position: absolute;
                                top: 110px;
                                left: 24px;
                                width: 336px;
                                height: 78px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #2C2C2D;
                                line-height: 26px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 3;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                            }

                            .evaluation-banner-img {
                                position: absolute;
                                top: 203px;
                                left: 24px;
                                width: 298px;
                                height: 86px;
                                display: flex;
                                align-items: center;

                                // justify-content:space-evenly;
                                .evaluation-banner-img-element {
                                    width: 86px;
                                    height: 86px;
                                    background-color: black;
                                }

                                .evaluation-banner-img-element:nth-child(2),
                                .evaluation-banner-img-element:nth-child(3) {
                                    margin-left: 20px;
                                }

                            }

                            .evaluation-banner-type {
                                position: absolute;
                                bottom: 20px;
                                left: 24px;
                                // width: 120px;
                                height: 25px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                line-height: 25px;
                                max-width: 230px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .evaluation-banner-coordinate {
                                position: absolute;
                                bottom: 20px;
                                right: 24px;
                                width: 100px;
                                height: 25px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: flex-start;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;

                                .icon {
                                    width: 20px;
                                    height: 20px;
                                }

                            }

                        }
                    }
                }
            }

        }



    }
}