* {
    margin: 0;
    padding: 0;
}

.familyservice {
    min-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .familyservice-top-img {
        width: 100%;
        height: 540px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .familyservice-icon {
        width: 100%;
        height: 224px;
        background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
        box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        .familyservice-icon-box {
            width: 1200px;
            height: 100%;

            .familyservice-icon-swiper {
                width: 160px !important;
                height: 224px !important;

                .familyservice-icon-swiper-icon {
                    width: 84px;
                    height: 84px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-left: 38px;
                    margin-top: 50px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .familyservice-icon-swiper-title {
                    width: 100%;
                    height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 24px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                }
            }
        }
    }

    .familyservice-title {
        width: 100%;
        height: 118px;
        display: flex;
        justify-content: center;
        background: #F9F9FA;

        .familyservice-title-zone {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .familyservice-title-zone-top {
                width: 282px;
                height: 45px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .familyservice-title-zone-top-icon {
                    width: 28px;
                    height: 22px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .familyservice-title-zone-top-title {
                    width: 190px;
                    height: 45px;
                    // background: #F9F9FA;
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2C2C2D;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .familyservice-title-zone-bottom {
                width: 308px;
                height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #6F7174;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .familyservice-content {
        width: 100%;
        height: 1398px;
        background: #F9F9FA;
        display: flex;
        align-items: center;
        justify-content: center;

        .familyservice-content-box {
            width: 1200px;
            height: 100%;
            position: relative;

            .familyservice-content-repair {
                position: absolute;
                width: 690px;
                height: 824px;
                // background: linear-gradient(180deg, #DBE8FA 0%, rgba(219, 232, 250, 0.78) 33%, rgba(219, 232, 250, 0) 100%);
                border-radius: 24px;
                top: 0;
                left: 0;

                .box {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    float: left;
                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #DBE8FA 0%, rgba(219, 232, 250, 0.78) 33%, rgba(219, 232, 250, 0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .familyservice-repair-title {
                            margin-top: 60px;
                            margin-left: 55px;
                            width: 336px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #08337B;
                            line-height: 67px;
                        }

                        .familyservice-repair-el {
                            margin-top: 10px;
                            margin-left: 55px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #08337B;
                            line-height: 45px;
                        }

                        .familyservice-repair-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 55px;
                            width: 202px;
                            height: 52px;
                            background: #08337B;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 33px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 4px;
                            }

                            .familyservice-repair-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 999999;

                                .familyservice-repair-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;

                                    .familyservice-repair-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .familyservice-repair-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }

                                    .familyservice-repair-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }

                        .familyservice-repair-btn:hover .familyservice-repair-code {
                            width: 265px;
                        }

                        .familyservice-repair-btn:hover .familyservice-repair-code .familyservice-repair-code-box .familyservice-repair-code-img {
                            width: 150px;
                        }

                        .familyservice-repair-btn:hover .familyservice-repair-code .familyservice-repair-code-box .familyservice-repair-code-title {
                            width: 144px;
                        }

                        .familyservice-repair-btn:hover .familyservice-repair-code .familyservice-repair-code-box .familyservice-repair-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }
                }

            }

            .familyservice-content-wash {
                position: absolute;
                top: 0;
                right: 0;
                width: 486px;
                height: 400px;
                background: linear-gradient(180deg, #FFE1DD 0%, rgba(255, 225, 221, 0.78) 34%, rgba(255, 225, 221, 0) 100%);
                border-radius: 24px;

                .box {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    float: left;
                    border-radius: 24px;
                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }
                    

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #FFE1DD 0%, rgba(255, 225, 221, 0.78) 34%, rgba(255, 225, 221, 0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .familyservice-wash-title {
                            margin-top: 60px;
                            margin-left: 15px;
                            // width: 395px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #791207;
                            line-height: 67px;
                            white-space: nowrap;
                        }

                        .familyservice-wash-el {
                            margin-top: 10px;
                            margin-left: 15px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #8B0D00;
                        }

                        .familyservice-wash-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 15px;
                            width: 202px;
                            height: 52px;
                            background: #791207;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }

                            .familyservice-wash-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 999999;

                                .familyservice-wash-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;

                                    .familyservice-wash-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .familyservice-wash-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }

                                    .familyservice-wash-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }

                        .familyservice-wash-btn:hover .familyservice-wash-code {
                            width: 265px;
                            z-index: 999999;
                        }

                        .familyservice-wash-btn:hover .familyservice-wash-code .familyservice-wash-code-box .familyservice-wash-code-img {
                            width: 150px;
                        }

                        .familyservice-wash-btn:hover .familyservice-wash-code .familyservice-wash-code-box .familyservice-wash-code-title {
                            width: 144px;
                        }

                        .familyservice-wash-btn:hover .familyservice-wash-code .familyservice-wash-code-box .familyservice-wash-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }




                }


            }


            .familyservice-content-clean {
                position: absolute;
                right: 0;
                top: 424px;
                width: 486px;
                height: 400px;
                background: linear-gradient(180deg, #CEF2F6 0%, rgba(206,242,246,0.78) 34%, rgba(206,242,246,0) 100%);
                border-radius: 24px;

                .box {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    float: left;

                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }
                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #CEF2F6 0%, rgba(206,242,246,0.78) 34%, rgba(206,242,246,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .familyservice-clean-title {
                            margin-top: 60px;
                            margin-left: 15px;
                            // width: 395px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #025860;
                            white-space: nowrap;
                        }

                        .familyservice-clean-el {
                            margin-top: 10px;
                            margin-left: 15px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #075158;
                        }

                        .familyservice-clean-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 15px;
                            width: 202px;
                            height: 52px;
                            background: #025860;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }

                            .familyservice-clean-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 99999;

                                .familyservice-clean-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;

                                    .familyservice-clean-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .familyservice-clean-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }

                                    .familyservice-clean-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }

                        .familyservice-clean-btn:hover .familyservice-clean-code {
                            width: 265px;
                        }

                        .familyservice-clean-btn:hover .familyservice-clean-code .familyservice-clean-code-box .familyservice-clean-code-img {
                            width: 150px;
                        }

                        .familyservice-clean-btn:hover .familyservice-clean-code .familyservice-clean-code-box .familyservice-clean-code-title {
                            width: 144px;
                        }

                        .familyservice-clean-btn:hover .familyservice-clean-code .familyservice-clean-code-box .familyservice-clean-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }

                    }
                }
            }

            .familyservice-content-install {
                position: absolute;
                left: 0;
                top: 848px;
                width: 588px;
                height: 400px;
                background: linear-gradient(180deg, #FFEAC7 0%, rgba(255,230,190,0.78) 34%, rgba(255,235,202,0) 100%);
                border-radius: 24px;

                .box {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    float: left;

                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }
                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #FFEAC7 0%, rgba(255,230,190,0.78) 34%, rgba(255,235,202,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .familyservice-install-title {
                            margin-top: 60px;
                            margin-left: 55px;
                            width: 480px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #111111;
                        }

                        .familyservice-install-el {
                            margin-top: 10px;
                            margin-left: 55px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #513408;
                        }

                        .familyservice-install-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 55px;
                            width: 202px;
                            height: 52px;
                            background: #613C03;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }

                            .familyservice-install-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 99999;

                                .familyservice-install-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;

                                    .familyservice-install-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .familyservice-install-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }

                                    .familyservice-install-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }

                        .familyservice-install-btn:hover .familyservice-install-code {
                            width: 265px;
                        }

                        .familyservice-install-btn:hover .familyservice-install-code .familyservice-install-code-box .familyservice-install-code-img {
                            width: 150px;
                        }

                        .familyservice-install-btn:hover .familyservice-install-code .familyservice-install-code-box .familyservice-install-code-title {
                            width: 144px;
                        }

                        .familyservice-install-btn:hover .familyservice-install-code .familyservice-install-code-box .familyservice-install-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }


                }


            }

            .familyservice-content-guarantee {
                position: absolute;
                top: 848px;
                right: 0;
                width: 588px;
                height: 400px;
                background: linear-gradient(180deg, #D1E4FF 0%, rgba(220,235,255,0.78) 33%, rgba(212,230,255,0) 100%);
                border-radius: 24px;
               

                .box {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    float: left;

                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #D1E4FF 0%, rgba(220,235,255,0.78) 33%, rgba(212,230,255,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .familyservice-guarantee-title {
                            margin-top: 60px;
                            margin-left: 55px;
                            width: 432px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #08337B;
                        }

                        .familyservice-guarantee-el {
                            margin-top: 10px;
                            margin-left: 55px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #08337B;
                        }

                        .familyservice-guarantee-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 55px;
                            width: 202px;
                            height: 52px;
                            background: #08337B;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }

                            .familyservice-guarantee-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 99999;

                                .familyservice-guarantee-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;

                                    .familyservice-guarantee-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .familyservice-guarantee-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }

                                    .familyservice-guarantee-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }

                        .familyservice-guarantee-btn:hover .familyservice-guarantee-code {
                            z-index: 99999999999;
                            width: 265px;
                        }

                        .familyservice-guarantee-btn:hover .familyservice-guarantee-code .familyservice-guarantee-code-box .familyservice-guarantee-code-img {
                            width: 150px;
                        }

                        .familyservice-guarantee-btn:hover .familyservice-guarantee-code .familyservice-guarantee-code-box .familyservice-guarantee-code-title {
                            width: 144px;
                        }

                        .familyservice-guarantee-btn:hover .familyservice-guarantee-code .familyservice-guarantee-code-box .familyservice-guarantee-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }

                    }


                }


            }
        }
    }

}