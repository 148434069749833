* {
    margin: 0;
    padding: 0;
}

.home {
    min-width: 1200px;
    width: 100%;
    // height: 2000px;
    overflow: hidden;

    .home-video {
        width: 100%;
        height: 900px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }

        .home-title {
            width: 1105px;
            height: 251px;
            z-index: 9;
            display: flex;
            flex-direction: column;
            position: relative;

            .home-title-top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 151px;
                font-size: 110px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 800;
                color: #FFFFFF;
                line-height: 151px;
                text-align: center;
                letter-spacing: 2px;
            }

            .home-title-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .home-title-bottom-text {
                    height: 100%;
                    // width: 170px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 50px;
                    letter-spacing: 3px;
                }

                .home-title-bottom-vertical-line {
                    height: 100%;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    div {
                        width: 2px;
                        height: 50%;
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }

    .home-service-zone-box {
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: center;

        .home-service-zone {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .home-service-zone-top {
                width: 230px;
                height: 45px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .home-service-zone-top-icon {
                    width: 28px;
                    height: 22px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .home-service-zone-top-title {
                    width: 138px;
                    height: 45px;
                    background-color: #FFFFFF;
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2C2C2D;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .home-service-zone-bottom {
                width: 128px;
                height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #6F7174;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

}