* {
    margin: 0;
    padding: 0;
}

.msxconsultation-detail {
    min-width: 1200px;
    width: 100%;
    overflow: hidden;

    .msxconsultation-detail-header {
        width: 100%;
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .msxconsultation-detail-header-box {
            position: relative;
            width: 1200px;
            height: 100%;

            .msxconsultation-detail-header-title {
                margin-top: 200px;
                width: 276px;
                height: 45px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 45px;

            }

            .msxconsultation-detail-header-tabs {
                margin-top: 16px;
                width: 175px;
                height: 26px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                line-height: 26px;

                .current {
                    color: #000;
                }

                cursor: pointer;
            }

            .msxconsultation-detail-header-advisory-floating-layer {
                position: absolute;
                left: 0;
                bottom: -36px;
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FFFFFF;

                .msxconsultation-detail-header-advisory-content {
                    height: 73px;
                    width: 1140px;
                    border-bottom: 1px solid #E6E6E6;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .title-left {
                        width: 200px;
                        height: 33px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                    }

                    .title-right {
                        width: 194px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        .zx {
                            height: 100%;
                            width: 72px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2C2C2D;
                            cursor: pointer;
                        }
                        .active{
                            color: #26C6A6;
                            border-bottom: 2px solid #26C6A6;
                        }
                    }
                }
            }
        }
    }

    .msxconsultation-detail-content-header {
        width: 100%;
        height: 217px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .msxconsultation-detail-title-box {
            width: 1140px;
            height: 181px;
            border-bottom: 1px solid #E6E6E6;

            .detail-name {
                width: 100%;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 60px;
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2C2C2D;
            }

            .detail-time {
                width: 100%;
                height: 26px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .msxconsultation-detail-content {
        width: 100%;
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        flex-direction: column;

        .msxconsultation-detail-content-box {
            width: 1140px;
            display: flex;
            flex-direction: column;
        }

        .msxconsultation-detail-content-box-bottom {
            width: 1140px;
            height: 60px;
            border-bottom: 1px solid #E6E6E6;
        }
    }

    .msxconsultation-detail-page {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .msxconsultation-detail-page-box {
            width: 1140px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .msxconsultation-detail-left {
                height: 100%;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
            }

            .msxconsultation-detail-right {
                height: 100%;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
            }
        }
    }
}

.ant-pagination-item a {
    border-radius: 4px;
    border: 1px solid #E6E6E6;
}

.ant-pagination-item-active a {
    background: #26C6A6;
    border-radius: 4px;
    color: #FFFFFF;
    border-color: #26C6A6 !important;
}

.ant-pagination .ant-pagination-item-active {
    border: none;
}

.ant-pagination .ant-pagination-item-active a {
    color: #FFFFFF;
}

.ant-pagination .ant-pagination-item-active a:hover {
    color: #FFFFFF;
}

.ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px !important;
    border: 1px solid #E6E6E6 !important;
}

.ant-pagination-jump-next-custom-icon,
.ant-pagination-item-link {
    border: none;
}

.ant-pagination-item-container {
    margin-left: -10px;
}
