* {
    margin: 0;
    padding: 0;
}

.housekeep {
    min-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .housekeep-top-img {
        width: 100%;
        height: 540px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .housekeep-icon {
        width: 100%;
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;

        .housekeep-icon-box {
            width: 1200px;
            height: 168px;
            background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 16px;
            margin-top: -84px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .housekeep-icon-items {
                width: 72px;
                height: 87px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;

                .icon {
                    width: 48px;
                    height: 48px;
                }

                .housekeep-icon-items-title {
                    width: 72px;
                    height: 26px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2D;
                    line-height: 26px;
                    text-align: center;
                }
            }
        }
    }

    .housekeep-title {
        width: 100%;
        height: 118px;
        display: flex;
        justify-content: center;
        // background-color: red;

        .housekeep-title-zone {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .housekeep-title-zone-top {
                width: 282px;
                height: 45px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .housekeep-title-zone-top-icon {
                    width: 28px;
                    height: 22px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .housekeep-title-zone-top-title {
                    width: 190px;
                    height: 45px;
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2C2C2D;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .housekeep-title-zone-bottom {
                width: 308px;
                height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #6F7174;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .housekeep-content {
        width: 100%;
        height: 918px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        .housekeep-content-box {
            width: 1200px;
            height: 100%;
            position: relative;

            .housekeep-content-repair {
                position: absolute;
                width: 588px;
                height: 824px;
                background: linear-gradient(180deg, #C5DDFF 0%, rgba(213,230,255,0.7) 41%, rgba(212,230,255,0) 100%);
                border-radius: 24px;
                background-color: green;
                top: 0;
                left: 0;
                .box{

                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #C5DDFF 0%, rgba(213,230,255,0.7) 41%, rgba(212,230,255,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        .housekeep-repair-title {
                            margin-top: 60px;
                            margin-left: 55px;
                            width: 336px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #025860;
                            line-height: 67px;
                        }
        
                        .housekeep-repair-el {
                            margin-top: 10px;
                            margin-left: 55px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #08337B;
                            line-height: 45px;
                        }
        
                        .housekeep-repair-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 55px;
                            width: 202px;
                            height: 52px;
                            background: #025860;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 33px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
        
                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 4px;
                            }
        
                            .housekeep-repair-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
        
                                .housekeep-repair-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
        
                                    .housekeep-repair-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;
        
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
        
                                    .housekeep-repair-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }
        
                                    .housekeep-repair-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }
        
                        .housekeep-repair-btn:hover .housekeep-repair-code {
                            width: 265px;
                        }
        
                        .housekeep-repair-btn:hover .housekeep-repair-code .housekeep-repair-code-box .housekeep-repair-code-img {
                            width: 150px;
                        }
        
                        .housekeep-repair-btn:hover .housekeep-repair-code .housekeep-repair-code-box .housekeep-repair-code-title {
                            width: 144px;
                        }
        
                        .housekeep-repair-btn:hover .housekeep-repair-code .housekeep-repair-code-box .housekeep-repair-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }

                   



                }
               
            }

            .housekeep-content-wash {
                position: absolute;
                top: 0;
                right: 0;
                width: 588px;
                height: 400px;
                background: linear-gradient(180deg, #FFDEDA 0%, rgba(255,221,216,0.78) 34%, rgba(255,232,229,0) 100%);
                border-radius: 24px;
                .box{
                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #FFDEDA 0%, rgba(255,221,216,0.78) 34%, rgba(255,232,229,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        .housekeep-wash-title {
                            margin-top: 60px;
                            margin-left: 45px;
                            width: 395px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #7A0F04;
                            line-height: 67px;
                        }
        
                        .housekeep-wash-el {
                            margin-top: 10px;
                            margin-left: 45px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #8B0D00;
                        }
        
                        .housekeep-wash-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 45px;
                            width: 202px;
                            height: 52px;
                            background: #791207;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
        
                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }
        
                            .housekeep-wash-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 99999;
        
                                .housekeep-wash-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
        
                                    .housekeep-wash-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;
        
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
        
                                    .housekeep-wash-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }
        
                                    .housekeep-wash-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }
        
                        .housekeep-wash-btn:hover .housekeep-wash-code {
                            width: 265px;
                        }
        
                        .housekeep-wash-btn:hover .housekeep-wash-code .housekeep-wash-code-box .housekeep-wash-code-img {
                            width: 150px;
                        }
        
                        .housekeep-wash-btn:hover .housekeep-wash-code .housekeep-wash-code-box .housekeep-wash-code-title {
                            width: 144px;
                        }
        
                        .housekeep-wash-btn:hover .housekeep-wash-code .housekeep-wash-code-box .housekeep-wash-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }
                }
                

            }


            .housekeep-content-clean {
                position: absolute;
                right: 0;
                top: 424px;
                width: 588px;
                height: 400px;
                background: linear-gradient(180deg, #FFEAC7 0%, rgba(255,230,190,0.78) 24%, rgba(255,235,202,0) 100%);
                border-radius: 24px;
                .box{
                    .imgbox{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                        overflow: hidden;
                        float: left;
                        .img {
                            width: 100%;
                            height: 100%;
 
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #FFEAC7 0%, rgba(255,230,190,0.78) 24%, rgba(255,235,202,0) 100%);
                        border-radius: 24px;
                    }

                    .content {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        .housekeep-clean-title {
                            margin-top: 60px;
                            margin-left: 45px;
                            width: 395px;
                            height: 67px;
                            font-size: 48px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #613C03;
                        }
        
                        .housekeep-clean-el {
                            margin-top: 10px;
                            margin-left: 45px;
                            // width: 195px;
                            height: 45px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #3C2400;
                        }
        
                        .housekeep-clean-btn {
                            position: relative;
                            margin-top: 40px;
                            margin-left: 45px;
                            width: 202px;
                            height: 52px;
                            background: #613C03;
                            border-radius: 28px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
        
                            span {
                                font-size: 21px;
                                color: #fff;
                                padding-left: 2px;
                                padding-top: 1px;
                            }
        
                            .housekeep-clean-code {
                                position: absolute;
                                left: 231px;
                                top: -20px;
                                width: 0;
                                height: 252px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                                border-radius: 24px;
                                // transition: all 0.9s;
                                z-index: 99999;
        
                                .housekeep-clean-code-box {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
        
                                    .housekeep-clean-code-img {
                                        width: 0;
                                        height: 150px;
                                        margin-top: 28px;
                                        // transition: all 0.6s;
        
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
        
                                    .housekeep-clean-code-title {
                                        width: 0;
                                        height: 33px;
                                        font-size: 24px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2C2C2D;
                                        line-height: 33px;
                                        overflow: hidden;
                                        // transition: all 0.6s;
                                    }
        
                                    .housekeep-clean-triangle {
                                        position: absolute;
                                        height: 0px;
                                        top: 40px;
                                        z-index: 9999999;
                                        // right: 40px;
                                        left: -20px;
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }
        
                        .housekeep-clean-btn:hover .housekeep-clean-code {
                            width: 265px;
                        }
        
                        .housekeep-clean-btn:hover .housekeep-clean-code .housekeep-clean-code-box .housekeep-clean-code-img {
                            width: 150px;
                        }
        
                        .housekeep-clean-btn:hover .housekeep-clean-code .housekeep-clean-code-box .housekeep-clean-code-title {
                            width: 144px;
                        }
        
                        .housekeep-clean-btn:hover .housekeep-clean-code .housekeep-clean-code-box .housekeep-clean-triangle {
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #fff transparent;
                        }
                    }
                }
                

            }
        }
    }

}