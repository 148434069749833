// 个人信息及地址填写
.information-fill-Skeleton {
    width: 1200px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.information-fill {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .information-fill-box {
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .information-fill-left {
            width: 741px;
            position: relative;

            .information-input {
                position: absolute;
                width: 100%;
                height: 60px;
                top: 60px;
                left: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .information-input-name {
                    width: 92px;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }

                .information-input-content {
                    position: relative;
                    width: 625px;
                    height: 100%;
                    background: #F9F9FA;
                    border-radius: 4px;
                    background-color: azure;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        right: 24px;
                    }

                    .address-items {
                        position: absolute;
                        left: 0;
                        top: 60px;
                        width: 100%;
                        // height: 200px;
                        z-index: 999;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 20px 0px rgba(106, 149, 145, 0.1), 0px 2px 10px 0px rgba(106, 149, 145, 0.04);
                        border-radius: 0px 0px 4px 4px;

                        .address-items-top {
                            width: 100%;
                            height: 54px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            background-color: rgba(249, 249, 250, 1);

                            .address-items-content {
                                height: 54px;
                                width: 96px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #2C2C2D;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                cursor: pointer;
                            }

                            .active {
                                background-color: #FFFFFF;
                            }
                        }

                        .address-items-bottom {
                            width: 100%;
                            height: 100%;

                            .address-items-bottom-items {
                                padding-left: 21px;
                                padding-right: 21px;
                                height: 36px;
                                border-radius: 4px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                line-height: 36px;
                                text-align: center;
                                float: left;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                margin-left: 10px;
                                cursor: pointer;
                            }

                            .active {
                                background: #26C6A6;
                                color: #FFFFFF;
                            }
                        }
                    }

                    .information-input-time {
                        position: absolute;
                        left: 0;
                        top: 60px;
                        width: 100%;
                        // height: 200px;
                        z-index: 999;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 20px 0px rgba(106, 149, 145, 0.1), 0px 2px 10px 0px rgba(106, 149, 145, 0.04);
                        border-radius: 0px 0px 4px 4px;
                        display: flex;
                        flex-direction: row;
                        // justify-content: space-between;

                        .information-input-time-left {
                            width: 200px;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center !important;
                            .information-input-time-left-items {
                                width: 180px;
                                height: 36px;
                                // margin-top: 16px;
                                // margin-left: 10px;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                border-radius: 4px;
                                border: none;
                                margin-top: 6px;
                                margin-bottom: 6px;
                            }

                            .active {
                                background: #26C6A6;
                                color: #ffffff;
                            }
                        }

                        .information-input-time-right {
                            width: 100%;
                            height: 100%;
                            margin-left: 24px;
                            background: rgba(249, 249, 250, 1);
                            // margin-bottom: 20px;

                            .intervalTime {
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                margin-top: 22px;
                                margin-left: 34px;
                            }

                            .intervalTime-items {
                                width: 151px;
                                height: 100%;
                                margin-left: 80px;
                                margin-top: -27px;

                                .information-input-time-right-btn {
                                    width: 151px;
                                    height: 36px;
                                    border-radius: 4px;
                                    font-size: 18px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #6F7174;
                                    margin-top: 6px;
                                    margin-bottom: 6px;
                                    background: rgba(249, 249, 250, 1);
                                    border: none;
                                }

                                .active {
                                    background: #26C6A6;
                                    color: #ffffff;
                                }
                            }

                        }
                    }

                    .ant-input {

                        width: 100%;
                        height: 100%;
                        background: #F9F9FA;
                        border-radius: 4px;
                        border: none;

                        &::placeholder {
                            font-size: 18px !important;
                            font-family: PingFangSC-Regular, PingFang SC !important;
                            font-weight: 400 !important;
                            color: #6F7174 !important;
                        }
                    }
                }
            }

            .contact-number {
                top: 140px;
            }

            .service-address {
                top: 220px;
            }

            .full-address {
                top: 300px;
            }

            .call-time {
                top: 380px;
            }

            .remarks {
                height: 108px;
                top: 465px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;

                .information-input-name {
                    height: 60px;
                }
            }

            .agreements {
                top: 588px;
                height: 26px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                line-height: 26px;
                padding-left: 10px;
                content: “”;
                display: block;
                clear: both;

                span {
                    color: rgba(38, 198, 166, 1);
                    cursor: pointer;
                }
            }

            .submits {
                top: 634px;
                // background-color: red;
                display: flex;
                flex-direction: row;
                align-items: center;
                content: “”;
                display: block;
                clear: both;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .submit-an-orders {
                    width: 196px;
                    height: 48px;
                    background: #26C6A6;
                    border-radius: 4px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                    border: none;
                }

                .the-next-steps {
                    width: 94px;
                    height: 48px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2C2C2D;
                    margin-left: 24px;
                    border: none;
                    background-color: #FFFFFF;
                }
            }

        }

        .information-fill-right {
            width: 435px;
            background: #F9F9FA;
            margin-top: 60px;

            .information-failure {
                width: 100%;
                height: 79px;
                display: flex;
                align-items: center;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                padding-left: 26px;
            }

            .information-failure-Machine-type {
                width: 100%;
                height: 138px;
                display: flex;
                justify-content: center;
                align-items: center;

                .information-failure-Machine-type-back {
                    width: 383px;
                    height: 138px;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .information-failure-Machine-wxjx {
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        width: 72px;
                        height: 26px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 26px;
                    }

                    .information-failure-Machine-hx {
                        width: 30px;
                        height: 2px;
                        position: absolute;
                        top: 58px;
                        left: 20px;
                        background: #FFFFFF;
                    }

                    .information-failure-Machine-name {
                        width: 100%;
                        height: 33px;
                        position: absolute;
                        left: 20px;
                        top: 72px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
            }

            .information-failure-wxfa {
                width: 100%;
                height: 26px;
                padding-left: 26px;
                margin-top: 30px;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
            }

            .information-failure-scheme {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;

                .information-failure-scheme-items {
                    width: 383px;
                    height: 52px;
                    margin-top: 5px;
                    border-bottom: 1px solid #E6E6E6;
                    // background-color: red;

                    .information-failure-scheme-name {
                        width: 100%;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .information-failure-scheme-name-left {
                            height: 100%;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2C2C2D;
                            line-height: 26px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            div {
                                width: 10px;
                                height: 10px;
                                background: #26C6A6;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                        }

                        .information-failure-scheme-name-right {
                            height: 100%;
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: rgba(38, 198, 166, 1);
                            line-height: 26px;
                        }
                    }
                }

                .information-failure-scheme-content {
                    width: 100%;
                    height: 100%;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;
                    padding-left: 20px;
                    padding-right: 125px;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                    display: -webkit-box;
                    -webkit-line-clamp: 3; // 超出多少行
                    -webkit-box-orient: vertical;
                    line-height: 22px;
                }
            }

            .information-failure-coupon {
                width: 100%;
                height: 57px;
                display: flex;
                justify-content: center;
                align-items: center;

                .information-failure-coupon-box {
                    width: 383px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E6E6E6;

                    .span1 {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                    }

                    .span2 {
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #26C6A6;
                        cursor: pointer;
                        span {
                            padding-top: -10px;
                        }
                    }
                }
            }

            .information-failure-zj {
                width: 100%;
                height: 79px;
                display: flex;
                justify-content: center;
                align-items: center;

                .information-failure-zj-box {
                    width: 383px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .span1 {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                    }

                    .span2 {
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #26C6A6;
                        cursor: pointer;

                        span {
                            padding-top: -10px;
                        }
                    }
                }
            }

            .information-failure-prompt {
                width: 100%;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #FFFFFF;

                .information-failure-prompt-box {
                    width: 383px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #6F7174;

                    .icon {
                        width: 16px;
                        height: 16px;
                    }

                }
            }

            .information-failure-prompt-content {
                width: 100%;
                height: 220px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                padding-left: 24px;
                background: #FFFFFF;
                line-height: 25px;
            }
        }
    }
}
.ant-btn-default:disabled {
    color: rgba(111, 113, 116, 0.5) !important;
}

#container {
    width: 100%;
    height: 100%;
    height: 600px;
    margin: 0px;
    font-size: 13px;
}

#pickerBox {
    position: absolute;
    z-index: 9999;
    top: 50px;
    right: 30px;
    width: 300px;
}

#pickerInput {
    width: 200px;
    padding: 5px 5px;
}

#poiInfo {
    background: #fff;
}

.amap_lib_placeSearch .poibox.highlight {
    background-color: #CAE1FF;
}

.amap_lib_placeSearch .poi-more {
    display: none!important;
}