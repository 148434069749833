.rotograph {
    width: 100%;
    // height: 1249px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .rotograph-box {
        width: 1200px;
        height: 373px;
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        .rotograph-box-left {
            width: 231px;
            height: 100%;
            box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.05);
            cursor: pointer;
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .rotograph-box-right {
            flex: 1;
            display: flex;
            flex-direction: column;

            .rotograph-box-right-top {
                width: 100%;
                height: 43px;
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: flex-start;
                .rotograph-box-right-top-lefts-box{
                    height: 100%;
                    width: 830px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    .rotograph-box-right-top-lefts {
                        // min-width: 100px;
                        height: 100%;
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: rgba(111, 113, 116, 1);
                        cursor: pointer;
                        flex-shrink: 0;
                        margin-left: 40px;
                    }
                    .active {
                        color: #26C6A6;
                    }
                    &::-webkit-scrollbar{
                        display:none;
                      }
                }

                

                .rotograph-box-right-top-right {
                    position: absolute;
                    height: 100%;
                    width: 104px;
                    right: 0;
                    top: 0;
                    // background-color: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #26C6A6;
                    cursor: pointer;
                    transition: all 0.4s;

                    .icon {
                        width: 16px;
                        height: 16px;
                        margin-bottom: 2px;
                        margin-right: 4px;
                    }
                }

                .rotograph-box-right-top-right:hover {
                    font-weight: 700;
                }

            }

            .rotograph-box-right-bottom {
                flex: 1;
                position: relative;
                height: 380px;
               
                .slick-prev {
                    margin-left: 37px;
                    margin-top: 150px;
                    z-index: 9;
                    width: 38px;
                    height: 46px;
                    background: rgba(0, 0, 0, 0.06);
                    border-radius: 0px 4px 4px 0px;
                }

                .slick-next {
                    margin-right: 43px;
                    margin-top: 150px;
                    z-index: 9;
                    width: 38px;
                    height: 46px;
                    background: rgba(0, 0, 0, 0.06);
                    border-radius: 0px 4px 4px 0px;
                }

                .slick-list {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 969px;
                    height: 380px;
                }

                .slick-next:before,
                .slick-prev:before {
                    color: rgba(38, 198, 166, 1);
                }

                .rotograph-box-right-swiper {
                    width: 255px !important;
                    height: 340px;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;

                    .rotograph-box-right-swiper-fe {
                        width: 231px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .rotograph-box-right-swiper-element {
                            width: 231px !important;
                            height: 320px !important;
                            background-color: #fff;
                            cursor: pointer;
                            transition: all 0.3s;
                            display: flex;
                            flex-direction: column;
                            // justify-content: center;
                            align-items: center;
                            background: #FFFFFF;
                            box-shadow: -8px -8px 20px 0px rgba(255, 255, 255, 0.2), 0px 8px 20px 0px rgba(106, 149, 145, 0.1);

                            .rotograph-box-right-swiper-element-img {
                                width: 148px;
                                height: 148px;
                                margin-top: 20px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .rotograph-box-right-swiper-element-name {
                                width: 100%;
                                height: 25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #2C2C2D;
                                margin-top: 5px;
                            }

                            .rotograph-box-right-swiper-element-information {
                                width: 100%;
                                height: 25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #6F7174;
                                margin-top: 5px;
                            }

                            .rotograph-box-right-swiper-element-price {
                                width: 100%;
                                height: 25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 5px;

                                .span-discount {
                                    font-size: 18px;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #26C6A6;
                                }

                                .span-price {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #6F7174;
                                    padding-left: 5px;
                                    text-decoration: line-through;
                                }
                            }

                            .rotograph-box-right-swiper-element-btn {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .button-immediate-repair {
                                    width: 88px;
                                    height: 26px;
                                    border-radius: 4px;
                                    border: 1px solid #26C6A6;
                                    color: #26C6A6;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .rotograph-box-right-swiper-element:hover {
                            box-shadow: 0 4px 14px 0 rgba(48, 55, 66, 0.15);
                            transform: scale(1.01)
                        }

                        .rotograph-box-right-swiper-element:hover .button-immediate-repair {
                            color: #fff;
                            background: #26C6A6;
                        }
                    }
                }
            }
        }
    }

}

