.cooperative-partner {
    width: 100%;
    height: 449px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cooperative-partner-box {
        width: 1200px;
        height: 100%;

        .cooperative-partner-center-title {
            width: 100%;
            height: 118px;
            display: flex;
            justify-content: center;
            // background-color: #6F7174;

            .cooperative-partner-zone {
                width: 1200px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                .cooperative-partner-zone-top {
                    width: 230px;
                    height: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .cooperative-partner-zone-top-icon {
                        width: 28px;
                        height: 22px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .cooperative-partner-zone-top-title {
                        width: 138px;
                        height: 45px;
                        background-color: #FFFFFF;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2C2C2D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .cooperative-partner-zone-bottom {
                    width: 308px;
                    height: 40px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #6F7174;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .cooperative-partner-content {
            width: 100%;
            height: 331px;
            display: flex;
            justify-content: center;
            align-items: center;

            .cooperative-partner-content-box {
                width: 1128px;
                height: 240px;
                // background-color: red;
                margin-bottom: 60px;

                .cooperative-partner-module {
                    width: 282px;
                    height: 120px;
                    float: left;
                    cursor: pointer;
                    transition: all 0.2s;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .cooperative-partner-module:hover {
                    box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
                    transform: scale(1.01)
                }

                .icon-top {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                .icon-top-right {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }

                .icon-bottom {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                .icon-bottom-right {
                    border: none;
                }
            }
        }
    }
}