.service-agreement {
    width: 100%;
    height: 100%;

    .service-agreement-title {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #26C6A6;
    }

    .service-agreement-introduction {
        margin-top: 40px;
        width: 100%;

        .introduction-title {
            width: 100%;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
        }

        .introduction-service {
            width: 100%;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            margin-top: 16px;
        }

        .introduction-content {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            margin-top: 16px;
            line-height: 32px;
        }

    }

    .main-body {
        width: 100%;
        margin-top: 48px;

        .main-name {
            width: 100%;
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
        }

        .main-content {
            margin-top: 24px;
            width: 100%;

            .big-title {
                width: 100%;
                // height: 32px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 32px;
            }

            .small-title {

                width: 100%;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
                line-height: 32px;
            }
        }
    }

    .service-agreement-footer {
        margin-top: 48px;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2C2C2D;
    }
}