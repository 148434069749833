.modal-envelop {
    width: 100%;
    height: 483px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-envelop-title {
        width: 100%;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2C2C2D;
    }

    .modal-envelop-itemBOX {
        width: 384px;
        height: 418px;
        margin-top: 16px;
        overflow: scroll;

        .modal-envelop-items {
            position: relative;
            width: 100%;
            height: 102px;
            margin-top: 16px;
            cursor: pointer;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }

            .modal-envelop-items-price {
                position: absolute;
                height: 74px;
                font-size: 64px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7100;
                line-height: 74px;
                -webkit-background-clip: text;
                top: 16px;
                left: 8px;

                span {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FF7100;
                    line-height: 25px;
                    -webkit-background-clip: text;
                }
            }

            .modal-envelop-items-type {
                position: absolute;
                top: 28px;
                left: 138px;
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FF7100;
                line-height: 25px;
                -webkit-background-clip: text;
            }

            .modal-envelop-items-subtraction {
                position: absolute;
                top: 57px;
                left: 138px;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF7100;
                line-height: 17px;
                -webkit-background-clip: text;
            }

            .modal-envelop-items-button {
                position: absolute;
                top: 36px;
                right: 20px;
                width: 72px;
                height: 30px;
                background: #FFFFFF;
                border-radius: 18px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF7100;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
            }
        }
    }

    .modal-envelop-itemBOX::-webkit-scrollbar {
        display: none
    }

    .modal-envelop-empty {
        width: 212px;
        height: 165px;
        margin-top: 70px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .modal-envelop-empty-title {
        margin-top: 4px;
        width: 100%;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6F7174;
    }
}