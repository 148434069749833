.raise-one-head {
    width: 100%;
    height: 60px;
    background: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;

    .footer-bot {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;

        .has-no-underline {
            margin-left: 4px;
        }
    }

    a {
        text-decoration: none;
        color: #FFFFFF;
    }
}