.home-introduce {
    position: relative;
    width: 100%;
    height: 344px;
    display: flex;
    justify-content: center;

    .home-introduce-num-container {
        position: absolute;
        top: -84px;
        .home-introduce-num {
            width: 1200px;
            height: 168px;
            background: linear-gradient(180deg, #F5F6F8 0%, #FEFDFE 100%) #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 16px;
            display: flex;
            flex-direction: row;

            .home-introduce-num-module {
                flex: 1;
                margin: 2px;
                display: flex;
                justify-content: center;
                align-items: center;

                .home-introduce-num-box {
                    // width: 100px;
                    height: 106px;

                    .home-introduce-num-box-top {
                        width: 100%;
                        height: 81px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;

                        .ant-statistic-content-value {
                            font-size: 58px;
                        }

                        .ant-statistic .ant-statistic-content .ant-statistic-content-suffix {
                            font-size: 58px !important;
                        }

                        .statistic {
                            font-size: 58px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #2C2C2D;
                        }


                        .home-introduce-num-box-j {
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #26C6A6;
                        }
                    }

                    .home-introduce-num-box-left {
                        width: 100%;
                        height: 25px;
                        font-size: 18px;
                        // font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6F7174;
                        display: flex;
                        justify-content: flex-start;
                        // line-height: 25px;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        .home-introduce-num-tip {
            font-size: 12px;
            color: #999;
            position: absolute;
            bottom: 10px;
            left: 20px;
        }
    }

    .home-introduce-center {
        position: absolute;
        width: 1200px;
        height: 98px;
        top: 84px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .home-introduce-center-box {
            width: 210px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6F7174;

            .icon {
                height: 28px;
                width: 28px;
            }
        }
    }

    .home-introduce-bottom {
        position: absolute;
        width: 1200px;
        height: 200px;
        bottom: 0;
        // background-color: yellow;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .home-introduce-bottom-box {
            width: 354px;
            height: 110px;
            // background-color: rgba(217, 249, 243, 1);
            text-align: center;
            transform: skewX(-15deg);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-color: #ffffff;

            .icon {
                width: 74px;
                height: 74px;
                transform: skewX(15deg);
            }

            .home-introduce-bottom-box-title {
                transform: skewX(15deg);
                width: 198px;
                height: 74px;
                display: flex;
                flex-direction: column;
                // background-color: red;
                margin-left: 10px;

                .home-introduce-bottom-box-title-top {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 24px;
                    font-weight: 500;
                    color: #2C2C2D;
                }

                .home-introduce-bottom-box-title-bottom {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 400;
                    color: #6F7174;
                }
            }
        }

        .the-first {
            background: rgba(217, 249, 243, 1);
            transition: all 0.3s;

        }

        .the-second {
            background: rgba(237, 234, 248, 1);
            transition: all 0.3s;
        }

        .the-third {
            background: rgba(249, 236, 217, 1);
            transition: all 0.3s;
        }

        .the-first:hover {
            background: -webkit-linear-gradient(rgba(38, 198, 166, 0.40), rgba(217, 249, 243, 1));
            background: -o-linear-gradient(rgba(38, 198, 166, 0.40), rgba(217, 249, 243, 1));
            background: -moz-linear-gradient(rgba(38, 198, 166, 0.40), rgba(217, 249, 243, 1));
            background: linear-gradient(rgba(38, 198, 166, 0.40), rgba(217, 249, 243, 1));
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid #FFFFFF;
        }

        .the-second:hover {
            background: -webkit-linear-gradient(rgba(132, 102, 255, 0.40), rgba(237, 234, 248, 1));
            background: -o-linear-gradient(rgba(132, 102, 255, 0.40), rgba(237, 234, 248, 1));
            background: -moz-linear-gradient(rgba(132, 102, 255, 0.40), rgba(237, 234, 248, 1));
            background: linear-gradient(rgba(132, 102, 255, 0.40), rgba(237, 234, 248, 1));
            box-shadow: 8px 8px 20px 0px rgba(136, 106, 149, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid #FFFFFF;
        }

        .the-third:hover {
            background: -webkit-linear-gradient(rgba(242, 172, 65, 0.50), rgba(249, 236, 217, 1));
            background: -o-linear-gradient(rgba(242, 172, 65, 0.50), rgba(249, 236, 217, 1));
            background: -moz-linear-gradient(rgba(242, 172, 65, 0.50), rgba(249, 236, 217, 1));
            background: linear-gradient(rgba(242, 172, 65, 0.50), rgba(249, 236, 217, 1));
            box-shadow: 8px 8px 20px 0px rgba(149, 133, 106, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid #FFFFFF;
        }
    }
}