.serviceProcess {
    width: 100%;
    height: 100%;

    .serviceProcess-title {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #26C6A6;
    }

    .serviceProcess-tabs {
        margin-top: 40px;
        width: 100%;
        height: 109px;
        display: flex;

        .tabs-left {
            width: 288px;
            height: 100%;
            width: 288px;
            height: 109px;
            background: rgba(39, 200, 166, 0.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
                width: 68px;
                height: 68px;
            }

            .tabs-left-title {
                margin-left: 16px;
                width: 120px;
                height: 69px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .smwx {
                    width: 100%;
                    height: 33px;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #26C6A6;
                    line-height: 33px;
                }

                .lc {
                    width: 100%;
                    height: 32px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #26C6A6;
                    line-height: 32px;
                }
            }
        }

        .tabs-left-active {
            background: #F9F9FA;

            .tabs-left-title {
                .smwx {
                    color: #6F7174;
                }

                .lc {
                    color: #6F7174;
                }
            }
        }

        .tabs-right {
            margin-left: 24px;
            width: 288px;
            height: 100%;
            width: 288px;
            height: 109px;
            background: rgba(39, 200, 166, 0.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
                width: 68px;
                height: 68px;
            }

            .tabs-right-title {
                margin-left: 16px;
                width: 120px;
                height: 69px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .smwx {
                    width: 100%;
                    height: 33px;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #26C6A6;
                    line-height: 33px;
                }

                .lc {
                    width: 100%;
                    height: 32px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #26C6A6;
                    line-height: 32px;
                }
            }
        }

        .tabs-right-active {
            background: #F9F9FA;

            .tabs-right-title {
                .smwx {
                    color: #6F7174;
                }

                .lc {
                    color: #6F7174;
                }
            }
        }
    }

    .serviceProcess-content {
        width: 100%;

        .active {
            margin-top: 40px;
        }

        .serviceProcess-content-items {
            margin-top: 60px;
            width: 100%;
            height: 273px;
            display: flex;
            flex-direction: row;

            .items-left-service {
                width: 456px;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .items-right-service {
                width: 456px;
                height: 100%;
                position: relative;

                .items-nums {
                    position: absolute;
                    top: 20px;
                    left: 40px;
                    width: 96px;
                    height: 103px;
                    font-size: 86px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    color: #26C6A6;
                    line-height: 103px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        width: 100%;
                        height: 100%;
                    }
                }

                .nums-active {
                    left: 321px;
                }



                .items-bjxd {
                    position: absolute;
                    top: 140px;
                    left: 40px;
                    width: 130px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2C2C2D;

                    div {
                        margin-left: 4px;
                    }

                    .icon {
                        width: 18px;
                        height: 18px;
                    }
                }

                .items-bjxd-active {
                    left: 299px;
                    justify-content: flex-end;

                    div {
                        margin-right: 4px;
                    }

                    .icon {
                        width: 18px;
                        height: 18px;
                        transform: rotate(180deg);
                    }
                }

                .items-content {
                    position: absolute;
                    top: 178px;
                    left: 40px;
                    width: 417px;
                    // height: 64px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6F7174;

                    line-height: 32px;
                }

                .items-content-active {
                    text-align: right;
                    padding-right: 39px;
                }
            }
        }
    }
}