.aboutus-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #26C6A6;

    .aboutus-tabs-box {
        width: 1200px;
        display: flex;
        flex-direction: row;

        .aboutus-tabs-left {
            width: 238px;
            height: 340px;
            background: #F9F9FA;
            display: flex;
            flex-direction: column;

            .aboutus-tabs-left-items {
                width: 100%;
                height: 68px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                cursor: pointer;
                transition: all 0.4s;
                position: relative;

                .arrow {
                    position: absolute;
                    right: -7px;
                    width: 7px;
                    height: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .active {
                background: #26C6A6;
                color: #FFFFFF;
            }

            .aboutus-tabs-left-items:hover {
                background-color: rgba(38, 198, 166, 0.1);
                color: #26C6A6;
            }
        }

        .aboutus-tabs-right {
            flex: 1;
            margin-left: 56px;
        }
    }
}