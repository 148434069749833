.head-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 92px;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 1200px;
    z-index: 9999999999;
    color: #ffffff;

    .head-navigation-left {
        height: 35px;
        width: 45%;
        display: flex;
        flex-direction: row;

        .head-navigation-left-logo {
            height: 100%;
            height: 100%;
            width: 29%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 180px;
            margin-top: -4px;
            cursor: pointer;

            .head-navigation-left-logo-img {
                width: 105px;
                height: 35px;
            }
        }

        .head-navigation-left-contentsy {
            flex: 0.6;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 46px;
            cursor: pointer;
            transition: all 0.5s;
        }

        .head-navigation-left-contentsy:hover {
            // color: #26C6A6;
            font-weight: 800;
            font-family: PingFangSC-Medium, PingFang SC;
        }

        .head-navigation-left-content {
            flex: 0.8;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 80px;
            cursor: pointer;
            transition: all 0.5s;
            position: relative;

            .enter-select {
                position: absolute;
                width: 180px;
                height: 0px;
                background: #FFFFFF;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
                right: 0;
                top: 62px;
                transition: all 0.2s;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 999999;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .enter-select-item {
                    width: 100%;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2C;
                }
            }
        }

        .bigStyle:hover .enter-select {
            height: 168px;
        }

        .head-navigation-left-content:hover {
            // color: #26C6A6;
            font-weight: 800;
            font-family: PingFangSC-Medium, PingFang SC;
        }

        .zm {
            min-width: 98px;
            flex: 1;
        }
    }

    .head-navigation-left-more-box {
        height: 100%;
        width: 25%;
        display: flex;
        flex-direction: row;

        .head-navigation-left-logo {
            height: 100%;
            height: 100%;
            width: 29%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 180px;
            margin-top: -4px;
            cursor: pointer;

            .head-navigation-left-logo-img {
                width: 105px;
                height: 35px;
            }
        }

        .head-navigation-left-contentsy {
            flex: 0.6;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 46px;
            cursor: pointer;
            transition: all 0.5s;
        }

        .head-navigation-left-contentsy:hover {
            // color: #26C6A6;
            font-weight: 800;
            font-family: PingFangSC-Medium, PingFang SC;
        }

        .head-navigation-left-content {
            flex: 0.8;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 80px;
            cursor: pointer;
            transition: all 0.5s;
        }

        .head-navigation-left-more {
            flex: 0.8;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 80px;
            cursor: pointer;
            transition: all 0.5s;
            position: relative;

            .more-box {
                position: absolute;
                left: -40%;
                top: 66px;
                width: 180px;
                height: 0;
                background: linear-gradient(180deg, #F4F5F8 0%, #FFFFFF 100%);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                transition: all 0.2s;

                .more-box-item {
                    width: 100%;
                    height: 25px;
                    margin-top: 24px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C2C2C;
                    padding-left: 24px;
                }

                .more-box-item:hover {
                    font-weight: 800;
                    color: #26C6A6;
                }
            }
        }

        .head-navigation-left-content:hover {
            font-weight: 800;
            font-family: PingFangSC-Medium, PingFang SC;
        }

        .head-navigation-left-more:hover .more-box {
            height: 271px;
        }

        .zm {
            min-width: 98px;
            flex: 1;
        }
    }

    .head-navigation-search {
        height: 100%;
        width: 272px;
        margin-left: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // background-color: red;
        min-width: 202px;

        .input-search {
            position: absolute;
            width: 100%;
            // height: 300px;
            background-color: #FFF;
            color: #000;
            top: 76px;
            left: 0;
            border-radius: 4px;
            display: flex;
            flex-direction: column;

            div {
                width: 100%;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 5px;
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                margin-top: 3px;
                overflow: hidden;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                line-height: 40px;
            }

            div:hover {
                background: rgba(0, 0, 0, 0.06);
            }
        }

        .head-navigation-input {
            width: 100%;
            height: 44px;
            border-radius: 4px;
        }

        .ant-input {
            background-color: rgba(0, 0, 0, 0.1);
            color: #FFFFFF !important;
            border: none !important;
            height: 44px;
            cursor: pointer;

            &::placeholder {
                color: #fff !important;
            }
        }

        .ant-input-active {
            background-color: rgba(0, 0, 0, 0.1);
            color: #000 !important;
            border: none !important;
            height: 44px;
            cursor: pointer;

            &::placeholder {
                color: rgba(44, 44, 44, 0.49) !important;
            }
        }

        .ant-btn {
            height: 44px;
            background-color: rgba(0, 0, 0, 0.1);
            border: none !important;
            color: #FFFFFF !important;
            font-size: 20px;
            margin-left: 1px;
        }

        .ant-btn-active {
            color: #26C6A6 !important;
        }
    }

    .head-navigation-right {
        // flex: 1;
        width: 590px;
        height: 100%;
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-left: 16px;

        .head-navigation-right-content {
            position: relative;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            font-size: 18px;
            transition: all 0.5s;

            .small-program-box {
                position: absolute;
                width: 272px;
                height: 0px;
                background: #FFFFFF;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
                right: 0;
                top: 92px;
                transition: all 0.5s;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 999999;

                .small-program-box-center {
                    width: 240px;
                    height: 133px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .small-program-box-qrcode {
                        height: 100%;
                        width: 108px;
                        display: flex;
                        flex-direction: column;

                        .small-program-box-qrcode-qr {
                            width: 100%;
                            height: 108px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .small-program-box-qrcode-title {
                            flex: 1;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #4F5156;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            .small-program-triangle {
                position: absolute;
                height: 0px;
                top: 72px;
                z-index: 9999999;
                right: 30px;
            }

            .public-account-box {
                position: absolute;
                width: 140px;
                height: 0px;
                background: #FFFFFF;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
                left: 10px;
                top: 92px;
                transition: all 0.5s;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 999999;

                .public-account-box-qrcode {
                    width: 102px;
                    height: 124px;
                    display: flex;
                    flex-direction: column;

                    .public-account-box-qr {
                        width: 100%;
                        height: 104px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .public-account-box-title {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5156;
                    }
                }
            }

            .public-account-triangle {
                position: absolute;
                height: 0px;
                top: 72px;
                z-index: 9999999;
                right: 40px;
            }

            .head-navigation-right-content-down {
                width: 18px;
                height: 18px;

            }

            .icon {
                width: 18px;
                height: 18px;
                margin-left: 5px;
            }

            .login {
                width: 80%;
                height: 36px;
                border-radius: 18px;
                border: 1px solid #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                min-width: 77px;
                transition: all 0.5s;
            }

            .login-active {
                border-radius: 18px;
                border: 1px solid #26C6A6;
                color: #26C6A6;
            }

            .login:hover {
                color: #26C6A6;
                border: 1px solid #26C6A6;
            }

            .register {
                width: 80%;
                height: 36px;
                background: #FFFFFF;
                border-radius: 18px;
                color: #26C6A6;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                min-width: 77px;
                transition: all 0.5s;
            }

            .register-active {
                background: #26C6A6;
                border-radius: 18px;
                color: #ffffff;
            }
        }

        .head-navigation-right-content:hover {
            font-weight: 800;
            font-family: PingFangSC-Medium, PingFang SC;
            cursor: pointer;
        }

        // 登陆成功状态显示
        .success-login {
            height: 100%;
            // background-color: yellow;
            display: flex;
            align-items: center;
            margin-right: 50px;
            margin-left: 10px;
            min-width: 220px;

            .login-left {
                width: 28px;
                height: 28px;
                margin-bottom: 3px;

                img {
                    width: 100%;
                    height: 100%;
                }

                min-width: 28px;
            }

            .username-login-success {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-left: 8px;
                padding-top: 3px;
            }

            .quit-login {
                height: 25px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-left: 8px;
                cursor: pointer;
                min-width: 48px;
                padding-top: 3px;
            }

            .active {
                color: #000;
            }
        }

        .small-program:hover .small-program-box {
            height: 165px;
        }

        .small-program:hover .small-program-triangle {
            border-style: solid;
            border-width: 10px 10px 10px 10px;
            width: 0;
            height: 0;
            border-color: transparent transparent #fff transparent;

        }

        .public-account:hover .public-account-box {
            height: 165px;
        }

        .public-account:hover .public-account-triangle {
            border-style: solid;
            border-width: 10px 10px 10px 10px;
            width: 0;
            height: 0;
            border-color: transparent transparent #fff transparent;
        }
    }

    .right-active {
        margin-right: 50px;
    }
}

.head-navigation-active {
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
    color: black;
}

.loginModal {
    width: 100%;
    height: 581px;
    display: flex;
    align-items: center;
    position: relative;

    .loginModal-title {
        position: absolute;
        width: 100%;
        height: 43px;
        top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2C2C2D;
        flex-direction: column;

        div {
            width: 40px;
            height: 2px;
            background: #26C6A6;
        }
    }

    .loginModal-phone-box {
        width: 100%;
        height: 56px;
        position: absolute;
        top: 141px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loginModal-phone {
            width: 372px;
            height: 56px;
            border-radius: 4px;
            border: 1px solid #D5DEE7;
            position: relative;

            .ant-input {
                position: absolute;
                top: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
                border: none !important;
                padding-left: 40px !important;
                font-size: 18px !important;

                &::placeholder {
                    font-size: 18px !important;
                    font-family: PingFangSC-Regular, PingFang SC !important;
                    font-weight: 400 !important;
                    color: #8B8F97 !important;
                }
            }

            .phone-icon {
                position: absolute;
                left: 18px;
                top: 17px;
                width: 18px;
                height: 21px;

                .icon {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .loginModal-captcha-box{
        width: 100%;
        height: 56px;
        position: absolute;
        top: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
      
    .loginModal-code-box {
        width: 100%;
        height: 56px;
        position: absolute;
        top: 316px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loginModal-code {
            width: 372px;
            height: 56px;
            border-radius: 4px;
            border: 1px solid #D5DEE7;
            position: relative;

            .ant-input {
                position: absolute;
                top: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
                border: none !important;
                padding-left: 40px !important;
                font-size: 18px !important;

                &::placeholder {
                    font-size: 18px !important;
                    font-family: PingFangSC-Regular, PingFang SC !important;
                    font-weight: 400 !important;
                    color: #8B8F97 !important;
                }
            }

            .code-icon {
                position: absolute;
                left: 18px;
                top: 17px;
                width: 18px;
                height: 21px;

                .icon {
                    width: 100%;
                    height: 100%;
                }
            }

            .code-button {
                position: absolute;
                top: 8px;
                right: 12px;
                height: 40px;
                border-radius: 4px;

                .btn-item {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.4);
                    line-height: 26px;
                    border: none;
                }

                .active {
                    color: #26C6A6;
                    background: rgba(38, 198, 166, 0.1);
                }
            }
        }
    }

    .loginModal-agreement {
        width: 100%;
        height: 26px;
        position: absolute;
        top: 402px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loginModal-agreement-box {
            width: 372px;
            height: 100%;
            // background-color: green;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .left {
                width: 16px;
                height: 16px;
                border: 1px solid #D5DEE7;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 22px;
                    height: 22px;
                }
            }

            .right {
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 5px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6F7174;
                padding-top: 2px;

                span {
                    color: #26C6A6;
                    cursor: pointer;
                }
            }
        }
    }

    .login-submit {
        position: absolute;
        top: 458px;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-submit-box {
            width: 372px;
            height: 100%;

            .btn {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                background: #26C6A6;
                border-radius: 4px;
                border: none;
            }

            .active {
                background: rgba(38, 198, 166, 0.3);
                color: #FFFFFF;
            }
        }
    }

    .loginModal-go-Signin {
        width: 100%;
        height: 26px;
        position: absolute;
        top: 544px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6F7174;

        span {
            color: #26C6A6;
            cursor: pointer;
        }
    }
}

.order-inquiry {
    width: 100%;
    height: 462px;
    position: relative;

    .order-inquiry-title {
        position: absolute;
        width: 100%;
        height: 40px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            width: 26px;
            height: 26px;
        }

        div {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2C2C2D;
            margin-left: 4px;
        }
    }

    .order-inquiry-service {
        width: 100%;
        height: 25px;
        position: absolute;
        top: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6F7174;
    }

    .qrcode-order {
        width: 100%;
        height: 160px;
        position: absolute;
        top: 165px;
        display: flex;
        justify-content: center;
        align-items: center;

        .qrcode-img {
            height: 100%;
            width: 160px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .progress-bar {
            height: 100%;
            width: 18px;
            margin-left: 30px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .order-inquiry-right {
            width: 216px;
            height: 100%;
            margin-left: 22px;
            position: relative;

            .top {
                width: 100%;
                height: 25px;
                position: absolute;
                top: 14px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
            }

            .center {
                width: 100%;
                height: 25px;
                position: absolute;
                top: 68px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
            }

            .bottom {
                width: 100%;
                height: 25px;
                position: absolute;
                top: 120px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
            }
        }
    }

    .order-inquiry-btn {
        width: 100%;
        position: absolute;
        top: 374px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            width: 185px;
            height: 38px;
            background: #26C6A6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            border: none;
        }
    }
}

.ant-modal-content {
    z-index: 99999;
}

.searchInput {
    .head-navigation-input {
        background-color: rgba(0, 0, 0, 0.1);
        color: #000 !important;
        border: none !important;
        height: 44px;
        cursor: pointer;

        &::placeholder {
            color: rgba(44, 44, 44, 0.49) !important;
        }
    }
}