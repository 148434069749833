.ant-float-btn-group {
    z-index: 999999999999999999999;
    width: 84px;

    .ant-float-btn {
        width: 84px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-float-btn-body {
            width: 100%;
            height: 100%;

            .ant-float-btn-content {
                width: 100%;
                height: 100%;

                .ant-float-btn-icon {
                    width: 36px;
                    height: 36px;

                    .img {
                        width: 100%;
                        height: 100%;
                    }

                    .icon {
                        width: 100%;
                        height: 100%;
                    }
                }

                .ant-float-btn-description {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #93979E;
                }
            }
        }

    }
}

.others {
    height: 240px !important;
}

.phone-order {
    width: 145px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .box {
        width: 108px;
        height: 128px;
        display: flex;
        flex-direction: column;

        .title {
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5156;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            width: 100%;
            height: 108px;
        }
    }
}

.consul-customer-service {
    // width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    .ccs-items {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;

        p {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .ccs-items:hover {
        background-color: rgba(0, 0, 0, 0.01);
        color: #26C6A6;
        cursor: pointer;

        p {
            color: #26C6A6;
        }
    }
}

.ant-tooltip-inner {
    color: #333 !important;
    background-color: #fff !important;
}

.ant-tooltip-arrow::before {
    background-color: #fff !important;
}
// .dc-service{
//     transform: scale(0.4);
// }