    // 手机保修选项
    .placeorder-Red-envelope {
        width: 100%;
        height: 102px;
        background: #F9F9FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .pactive{
        opacity: 0;
        height: 0px;
    }
    .placeorder-Red-envelope-bottom {
        width: 100%;
        height: 60px;
        background: #F9F9FA;
    }
    .bomactive{
        opacity: 0;
        height: 0px;
    }
    .placeorder-logic-skeleton {
        width: 1200px;
        height: 300px;
        margin-top: 200px;
    }

    .placeorder-logic {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;


        .placeorder-logic-box {
            width: 100%;
            position: relative;
            min-height: 800px;


            .placeorder-logic-logo {
                position: absolute;
                width: 280px;
                height: 280px;
                left: 0px;
                top: 84px;

                img {
                    width: 280px;
                    height: 280px;
                    border: none;
                }
            }

            .placeorder-logic-name {
                // width: 450px;
                height: 75px;
                position: absolute;
                left: 435px;
                top: 84px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .placeorder-logic-name-top {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    flex-direction: row;

                    .placeorder-logic-name-leftName {
                        height: 100%;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2C2C2D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .placeorder-logic-name-right {
                        height: 100%;
                        width: 98px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #26C6A6;
                        margin-left: 11px;
                        cursor: pointer;

                        .icon {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }

                .placeorder-logic-name-bottom {
                    width: 100%;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .placeorder-logic-name-bottom-box {
                        width: 92px;
                        height: 100%;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6F7174;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    .active {
                        margin-left: 24px;
                    }
                }
            }

            .placeorder-logic-failure {
                position: absolute;
                width: 126px;
                height: 26px;
                top: 199px;
                left: 435px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 26px;

                span {
                    color: rgba(0, 0, 0, 0.4);
                    font-weight: 300;
                }
            }

            .placeorder-logic-choose {
                position: absolute;
                width: 781px;
                // height: 300px;
                top: 225px;
                left: 415px;


                .placeorder-logic-choose-items {
                    width: 170px;
                    height: 52px;
                    border-radius: 4px;
                    border: 1px solid #979797;
                    float: left;
                    margin-top: 20px;
                    margin-left: 20px;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(151, 151, 151, 1);
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #979797;
                }

                .ant-badge .ant-badge-count {
                    box-shadow: none;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    margin-top: 20px;
                }

                .active {
                    color: #26C6A6;
                    border: 1px solid #26C6A6;
                }
            }

            .placeorder-logic-maintenance {
                position: absolute;
                top: 429px;
                left: 435px;
                // width: 180px;
                height: 26px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2C2C2D;
                line-height: 26px;

                span {
                    color: rgba(38, 198, 166, 1);
                }
            }

            .placeorder-logic-maintenance-box {
                // position: absolute;
                // top: 475px;
                // left: 435px;
                margin-top: 475px;
                margin-left: 435px;
                width: 740px;
                display: flex;
                flex-direction: column;
                //   float: left;

                .placeorder-logic-maintenance-box-items {
                    top: 475px;
                    left: 435px;
                    background: #F9F9FA;
                    border-radius: 4px;
                    width: 100%;


                    .placeorder-logic-maintenance-items {
                        width: 220px;
                        height: 177px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin-top: 10px;
                        margin-left: 20px;
                        margin-bottom: 10px;
                        position: relative;
                        float: left;
                        cursor: pointer;

                        .placeorder-logic-maintenance-i-title {
                            position: absolute;
                            top: 16px;
                            left: 16px;
                            width: 189px;
                            height: 30px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2C2C2D;
                        }

                        .placeorder-logic-maintenance-i-content {
                            position: absolute;
                            top: 50px;
                            left: 16px;
                            width: 188px;
                            height: 44px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #6F7174;
                            line-height: 22px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .placeorder-logic-maintenance-i-price {
                            position: absolute;
                            top: 128px;
                            left: 16px;
                            width: 150px;
                            height: 33px;
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #26C6A6;
                            line-height: 33px;

                            span {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #26C6A6;
                                line-height: 22px;
                            }
                        }

                        .placeorder-logic-maintenance-i-choose {
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            top: 130px;
                            left: 174px;
                            border-radius: 50%;
                            cursor: pointer;

                            img {
                                width: 100%;
                                height: 100%;
                            }

                            .icon {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .active {
                        background: #FFFFFF;
                        box-shadow: -8px -8px 20px 0px rgba(255, 255, 255, 0.2), 0px 8px 20px 0px rgba(106, 149, 145, 0.1);
                        border-radius: 4px;
                        border: 1px solid #26C6A6;
                    }
                }

                .placeorder-logic-coupon {
                    width: 100%;
                    height: 90px;
                    margin-top: 40px;
                    border-bottom: 1px solid #E6E6E6;
                    border-top: 1px solid #E6E6E6;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .placeorder-logic-coupon-left {
                        width: 54px;
                        height: 26px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                        display: flex;
                        align-items: center;
                    }

                    .placeorder-logic-coupon-gotouse {
                        width: 78px;
                        height: 26px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6F7174;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: 49px;
                        cursor: pointer;
                        div{
                            color:red;
                        }
                        .icon {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .placeorder-logic-price {
                    width: 100%;
                    height: 96px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .placeorder-logic-price-title {
                        width: 72px;
                        height: 26px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2C2C2D;
                    }

                    .placeorder-logic-price-p {
                        width: 164px;
                        height: 33px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        font-size: 36px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #26C6A6;

                        span {
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #26C6A6;
                            margin-bottom: 4px;

                        }
                    }

                    .placeorder-logic-price-yh {
                        width: 120px;
                        height: 26px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }

                    .placeorder-The-next-step {
                        width: 194px;
                        height: 48px;
                        background: #26C6A6;
                        border-radius: 4px;
                        border: none;
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .placeorder-logic-bottom {
                    width: 100%;
                    height: 106px;
                }

            }
        }
    }